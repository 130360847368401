import Model from "./Model";
import Responses from "./Responses";
import Server from "../services/Server";
import Storage from "../services/Storage";
import Analytics from "../services/Analytics";

export default class User extends Model {
  id = null;
  type = null;
  locale = null;
  name = null;
  email = null;
  email_notifications = null;
  token = null;
  image = null;
  image_data = null;
  initials = null;
  clever_id = null;
  view_as_role = null;
  district_pref_no_third_party = false;
  district_pref_google_classroom = false;

  created_at = null;
  updated_at = null;
  invited_at = null;
  accepted_at = null;
  sign_in_at = null;
  sign_in_count = null;

  // NOTE: do we still need all these fields?
  toolkits_count = null;
  schools_count = null;
  teachers_count = null;
  students_count = null;
  notes_count = null;
  responses_count = null;
  announcements_count = null;

  toolkits_ids = [];
  school_ids = [];
  classroom_ids = [];
  teacher_ids = [];
  student_ids = [];

  abilities = null;
  collection_requests = null;
  school_names = [];

  available_collection_grade_bands = [];

  constructor(data = {}) {
    super(data);
    Object.assign(this, data);
    if (data && data["responses"]) {
      this.responses = new Responses(data["responses"]);
    }
    else {
      this.responses = new Responses();
    }
    if (data && data["invited_at"]) {
      this.invited_at = data["invited_at"];
    }
    if (data && data["accepted_at"]) {
      this.accepted_at = data["accepted_at"];
    }
  }

  async store() {
    return this._store("user");
  }

  static async restore() {
    return Model._restore(User, "user");
  }

  async save(image_data = null,
    remove_image = null,
    email_waypoints = null,
    locale = "en") {
    return this._save(User, "/users/profile.json", {
      name: this.name,
      locale: locale,
      image: image_data,
      remove_image: remove_image,
      email_waypoints: email_waypoints
    });
  }

  static async locale(locale) {
    let url = new URL("/users/profile.json", process.env.VUE_APP_API_URL).href;
    let response = await Server.post(url, {
      locale: locale
    });
    if (response) {
      const user = await this.updateStoredUser(response);
      return user;
    }
    return null;
  }

  static async accessibilityTools(boolean) {
    let url = new URL("/users/profile.json", process.env.VUE_APP_API_URL).href;
    let response = await Server.post(url, {
      accessibility_tools: boolean
    });
    if (response) {
      const user = await this.updateStoredUser(response);
      return user;
    }
    return null;
  }

  static async notificationSettings(user_id, boolean) {
    const path = `/users/${user_id}.json`;
    const response = await Server.patch(path, { user: { email_notifications: boolean } });
    if (response) {
      const user = await this.updateStoredUser(response);
      return user;
    }
    return null;
  }

  static async login(email, password, code, direct = null, key = null) {
    let url = new URL("/users/signin.json", process.env.VUE_APP_API_URL).href;

    let params = direct && key
      ? {
        direct,
        key
      }
      : {
        email,
        password,
        c: code
      };

    let response = await Server.post(url, params);
    if (response) {
      let user = new User(response);
      Analytics.setUser(user);
      return user;
    }
    return null;
  }

  static async google(email,
    name,
    image,
    id_token,
    access_token,
    refresh_token,
    expires_at,
    classroom_code = null) {
    let url = new URL("/users/google.json", process.env.VUE_APP_API_URL).href;
    let response = await Server.post(url, {
      email: email,
      name: name,
      image: image,
      id_token: id_token,
      access_token: access_token,
      refresh_token: refresh_token,
      expires_at: expires_at,
      c: classroom_code
    });
    if (response) {
      let user = new User(response);
      Analytics.setUser(user);
      return user;
    }
    return null;
  }

  static async clever(code, redirect_uri, classroom_code = null) {
    let url = new URL("/users/clever.json", process.env.VUE_APP_API_URL).href;
    let response = await Server.post(url, {
      code: code,
      redirect_uri: redirect_uri,
      c: classroom_code
    });
    if (response) {
      let user = new User(response);
      Analytics.setUser(user);
      return user;
    }
    return null;
  }

  static async classlink(code, classroom_code = null) {
    let url = new URL("/users/classlink.json", process.env.VUE_APP_API_URL)
      .href;
    let response = await Server.post(url, {
      classlink_code: code,
      c: classroom_code
    });
    if (response) {
      let user = new User(response);
      Analytics.setUser(user);
      return user;
    }
    return null;
  }

  static async saml(token) {
    let url = new URL("/users/saml/find_auth.json", process.env.VUE_APP_API_URL)
      .href;
    let response = await Server.post(url, {
      user_access_token: token
    });
    if (response) {
      let user = new User(response);
      Analytics.setUser(user);
      return user;
    }
    return null;
  }

  static async azure(jwt,
    id_token,
    email,
    expires_at,
    name,
    classroom_code = null) {
    let url = new URL("/users/azure.json", process.env.VUE_APP_API_URL).href;
    let response = await Server.post(url, {
      jwt: jwt,
      code: id_token,
      email: email,
      expires_at: expires_at,
      name: name,
      c: classroom_code,
      response_type: "Vue ML Oauth"
    });
    if (response) {
      let user = new User(response);
      Analytics.setUser(user);
      return user;
    }
    return null;
  }

  static async signup(type,
    name,
    email,
    password,
    image = null,
    classroom_code = null) {
    let url = new URL("/users.json", process.env.VUE_APP_API_URL).href;
    let response = await Server.post(url, {
      type: type,
      name: name,
      email: email,
      password: password,
      image: image,
      c: classroom_code
    });
    if (response) {
      let user = new User(response);
      Analytics.setUser(user);
      return user;
    }
    return null;
  }

  static async profile() {
    let url = new URL("/users/profile.json", process.env.VUE_APP_API_URL).href;
    let response = await Server.get(url);
    if (response) {
      let user = new User(response);
      Analytics.setUser(user);
      return user;
    }
    return null;
  }

  static async ltiLogin(params) {
    let url = new URL(`/lti/districts/${params.district_id}/tools/${params.tool_id}/launches/${params.launch_id}/profile.json`, process.env.VUE_APP_LTI_URL).href;
    let response = await Server.get(url);
    if (response) {
      let user = new User(response);
      Analytics.setUser(user);
      return user;
    }
    return null;
  }

  static async changeRole(params) {
    let url = `/users/view-as/${params.role}.json`;
    if (params.school) {
      url += `?set_school_id=${params.school}`;
    }
    url = new URL(url, process.env.VUE_APP_API_URL).href;
    let response = await Server.patch(url);
    if (response) {
      const user = await this.updateStoredUser(response);
      return user;
    }
    return null;
  }

  // need to refetch user object to check to see if term was updated form the backend
  async fetchUserTerm() {
    try {
      let url = new URL("/users/profile.json", process.env.VUE_APP_API_URL)
        .href;
      let response = await Server.get(url);
      let user = await Storage.get("user");
      user.current_term_id = response.current_term_id;
      await Storage.clear();
      await Storage.set("user", user);
      return user;
    }
    catch (error) {
      // ignore
    }
  }

  async setUsersTerm(term_id) {
    const path = `terms/${term_id}/set.json`;
    const response = await this._patch(User, path);

    let user = await Storage.get("user");
    user.current_term_id = response.current_term_id;
    await Storage.clear();
    await Storage.set("user", user);

    return user;
  }

  async impersonate(id) {
    const path = `/users/${id}/impersonate.json`;
    const response = await this._post(User, path);
    return response;
  }

  async stopImpersonating() {
    const path = "/users/stop-impersonating.json";
    const response = await this._post(User, path);
    return response;
  }

  // need to reset user with the original token, should look into alternatives in the future
  // token is null on the returned user object after updating
  static async updateStoredUser(response) {
    let user = new User(response);
    let oldUser = await Storage.get("user");
    user.token = oldUser.token;
    await Storage.set("user", user);
    return user;
  }

  static async logout() {
    Analytics.clearUser();
    return await Storage.clear();
  }

  firstName() {
    if (this.name && this.name.length > 0) {
      return this.name.split(" ")[0];
    }
    return "";
  }

  activeRole() {
    return this.view_as_role ? this.view_as_role : this.type;
  }

  isUser() {
    return this.activeRole() === "User";
  }

  isStaff() {
    return [
      "Staff", "Admin", "Developer", "ContentEditor", "Translator"
    ].includes(this.activeRole());
  }

  isManager() {
    return this.activeRole() === "Manager";
  }

  isPrincipal() {
    return this.activeRole() === "Principal";
  }

  isPrincipalOrAssistant() {
    return [
      "Principal", "Assistant"
    ].includes(this.activeRole());
  }

  isTeacher() {
    return this.activeRole() === "Teacher";
  }

  isStudent() {
    return this.activeRole() === "Student";
  }

  isAssistant() {
    return this.activeRole() === "Assistant";
  }

  isCounselor() {
    return this.activeRole() === "Counselor";
  }
}
